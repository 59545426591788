import React from "react";
import "reset-css";
import Link from "gatsby-link";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import classnames from "classnames";
import Nav from "../components/nav";
import styles from "./about.module.scss";
import Layout from "../components/layout";


const App = () => (
  <Layout>
    <Helmet title="404 • The Bronx Direct" />
    <Nav theme="dark" />
    <div className={styles.container}>
      <h1 className={styles.h1}>YERRR! 404 Error</h1>
      <p>
        You've landed on a page that has been moved or does not exist. 
      </p>
      <div className={styles.backContainer}>
        <Link to="/" className={styles.backLink}>
          Return to Home ✌️
        </Link>
      </div>
    </div>
  </Layout>
);

export default App;
